<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dense
        flat
        dark
        class="text-h6"
        :loading="loading"
        >Usuarios</v-toolbar
      >
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              label="Buscar"
              v-model="search"
              outlined
              dense
              :disabled="loading"
              hint="Presione 'Enter' para buscar"   
              prepend-inner-icon="mdi-account-search"
              @keyup.enter=" limpiarPagination(search)"
              ></v-text-field>
          </v-col>
        </v-row>
        <tablaUsuarios 
          :usuarios="usuarios" 
          :pagination="pagination" 
          @updatePageTable="updatePageTable"
          @paginaCambio="paginaCambio" 
          @updateSearch="(search) => limpiarPagination(search)"
          />
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import tablaUsuarios from "./tablaUsuarios.vue"
import { buscarUsuarios } from "./usuarios.service";
import { DateTime } from "luxon";
export default {
  components: {  tablaUsuarios },
  computed: {
    ...mapGetters(["sessionToken"]),
    
  },
  
  data: () => ({
    loading: false,
    usuarios: [],
    search: "",
    pagination: {
        page: 1,
        itemsPerPage: 50,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
  }),
  watch: {
    sessionToken(value) {
      if (value) this.cargarUsuarios();
    },
  },
  created() {
    this.pagina = this.$route.query.page || 1;
    if (this.$route.query.search) {
      this.pagina = 1;
      this.search = this.$route.query.search;
    }
    if (this.sessionToken) this.cargarUsuarios();
  },
  methods: {
    async cargarUsuarios(search) {
      try {
        let filtro = null;
        if (search) filtro = `busqueda=${search}`;
        this.loading = true;
        const sendData = {
          pagina: this.pagination.page,
          registros: this.pagination.itemsPerPage,
          busqueda: this.search,
        };
        const serverResponse = await buscarUsuarios(sendData, filtro);
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else{
          if(serverResponse.usuarios.length){
            let num = serverResponse.pageStart+1;
            this.usuarios = serverResponse.usuarios.map(x=>{
              var fechaRegistro = DateTime.fromISO(x.fechaRegistro);
              return {
               numero: num++,
               avatar: x.avatar,
               usuario:{
                _id:x._id,
                nombre:x.nombreCompleto,
                ...(x.highlights && { highlights: x.highlights })
               },
               correo: x.correo,
               status: x.fechaActivacion ? "Activo" : "Inactivo",
               discord: x.discord ? x.discord : null,
               registro: fechaRegistro.toLocaleString(DateTime.DATE_SHORT),
               monitores: x.monitor.length > 0,
               }
            })
          }
          else this.usuarios=[]
          this.pagination.itemsLength = serverResponse.total; 
          this.pagination.pageStop = serverResponse.pageStop;
          this.pagination.pageStart = serverResponse.pageStart;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    updatePageTable(value) {
      this.pagination.pageCount += 1;
      this.pagination.page = value.page;
      this.cargarUsuarios(this.search);
    },

    paginaCambio(pagina) {
      this.pagination.page = pagina;
      this.cargarUsuarios(this.search);
    },

    limpiarPagination(search) {
      this.pagination.page = 1;
      this.pagination.pageStart = 0;
      this.pagination.pageStop = 0;
      this.pagination.pageCount = 1;
      this.pagination.itemsLength = 0;
      this.cargarUsuarios(search);
    },
  },
};
</script>
