<template>
  <div>
    <datatable
      :loading="loading"
      :datos="usuarios"
      :pagination="pagination"
      :headers="headers"
      :page="pagination.page"
      :totalItems="pagination.itemsLength"
      :itemsPerPage="pagination.itemsPerPage"
      hideSearch
      @update-page="updatePageTable"
      @pagina-change="paginaCambio"
      @update-search="updateSearch"
    >
      <template #item-avatar="{ item, value }">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar size="35" style="margin-right: 10px">
              <v-icon size="30" v-if="!value">mdi-account</v-icon>
              <img v-else :src="`${imageURL}/icon/${value}`" alt="User" />
            </v-avatar>
          </v-list-item-avatar>
          <v-icon v-if="item.monitores" small color="orange">
            mdi-star
          </v-icon>
          <v-icon v-else small>
            mdi-star
          </v-icon>
        </v-list-item>
      </template>
      <template #item-usuario="{value}">
        <highlights-usuario
          v-if="value.highlights"
          :highlights="value.highlights"
          :idUsuario="value._id"
          :nombreUsuario="value.nombre"
        ></highlights-usuario>
        <router-link v-else class="link" :to="`usuario/${value._id}`">{{
          value.nombre
        }}</router-link>
      </template>
      <template #item-correo="foo">
        <highlights-correo-usuario
          v-if="foo.item.usuario.highlights"
          :highlights="foo.item.usuario.highlights"
          :idUsuario="foo.item.usuario._id"
          :correoUsuario="foo.value"
        ></highlights-correo-usuario>
        <router-link
          v-else
          class="link"
          :to="`usuario/${foo.item.usuario._id}`"
          >{{ foo.value }}</router-link
        >
      </template>
      <template #item-status="{ value }">
        <v-chip
          v-if="value == 'Activo'"
          small
          outlined
          label
          color="green darken-1"
          >{{ value }}</v-chip
        >
        <v-chip v-else small outlined label color="red darken-1">{{
          value
        }}</v-chip>
      </template>
    </datatable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datatable from "../globals/template/widgets/datatable.vue";

export default {
  name: "TablaUsuarios",
  components: {
    datatable,
    HighlightsUsuario: () => import("./HighlightsNombreUsuario.vue"),
    HighlightsCorreoUsuario: () => import("./HighlightsCorreoUsuario.vue"),
  },
  props: {
    loading: { type: Boolean, default: () => false },
    usuarios: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  computed: {
    ...mapGetters(["sessionToken", "imageURL"]),
    esAdmin() {
      return (
        this.app == "campus" || this.role == "admin" || this.role == "profesor"
      );
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "10px",
        },
        {
          text: "",
          value: "avatar",
          sortable: false,
          filterable: false,
          align: "center",
          width: "10px",
        },
        {
          text: "Nombre",
          value: "usuario",
          sortable: false,
          filterable: false,
        },
        {
          text: "Correo",
          value: "correo",
          sortable: false,
          filterable: false,
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
        {
          text: "Discord",
          value: "discord",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
        {
          text: "Registro",
          value: "registro",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
    };
  },
  methods: {
    updatePageTable(value) {
      this.$emit("updatePageTable", value);
    },
    paginaCambio(pagina) {
      this.$emit("paginaCambio", pagina);
    },
    updateSearch(search) {
      this.$emit("updateSearch", search);
    },
  },
};
</script>
