import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('datatable',{attrs:{"loading":_vm.loading,"datos":_vm.usuarios,"pagination":_vm.pagination,"headers":_vm.headers,"page":_vm.pagination.page,"totalItems":_vm.pagination.itemsLength,"itemsPerPage":_vm.pagination.itemsPerPage,"hideSearch":""},on:{"update-page":_vm.updatePageTable,"pagina-change":_vm.paginaCambio,"update-search":_vm.updateSearch},scopedSlots:_vm._u([{key:"item-avatar",fn:function({ item, value }){return [_c(VListItem,[_c(VListItemAvatar,[_c(VAvatar,{staticStyle:{"margin-right":"10px"},attrs:{"size":"35"}},[(!value)?_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":`${_vm.imageURL}/icon/${value}`,"alt":"User"}})],1)],1),(item.monitores)?_c(VIcon,{attrs:{"small":"","color":"orange"}},[_vm._v(" mdi-star ")]):_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-star ")])],1)]}},{key:"item-usuario",fn:function({value}){return [(value.highlights)?_c('highlights-usuario',{attrs:{"highlights":value.highlights,"idUsuario":value._id,"nombreUsuario":value.nombre}}):_c('router-link',{staticClass:"link",attrs:{"to":`usuario/${value._id}`}},[_vm._v(_vm._s(value.nombre))])]}},{key:"item-correo",fn:function(foo){return [(foo.item.usuario.highlights)?_c('highlights-correo-usuario',{attrs:{"highlights":foo.item.usuario.highlights,"idUsuario":foo.item.usuario._id,"correoUsuario":foo.value}}):_c('router-link',{staticClass:"link",attrs:{"to":`usuario/${foo.item.usuario._id}`}},[_vm._v(_vm._s(foo.value))])]}},{key:"item-status",fn:function({ value }){return [(value == 'Activo')?_c(VChip,{attrs:{"small":"","outlined":"","label":"","color":"green darken-1"}},[_vm._v(_vm._s(value))]):_c(VChip,{attrs:{"small":"","outlined":"","label":"","color":"red darken-1"}},[_vm._v(_vm._s(value))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }